<template>
    <div class="card flex flex-col flex-1 card-f-h">
        <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
            <h1>{{ $t('common.invoices') }}</h1>
        </header>

        <Table
            v-if="invoicesData.length > 0"
            :tableData="invoicesData"
            @print="handlePrintDownload"
            @download="handlePrintDownload"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import Api from './invoices.api';

export default {
    components: {
        Table: () => import(/* webpackChunkName: "ClientInvoicesTable" */ './InvoicesTable'),
    },

    props: {
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            noDataInformation: false,
            invoicesData:      [],
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_invoices');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.initial_invoices');
            try {
                this.invoicesData = await Api.getAll(this.clientId);
                this.noDataInformation = this.invoicesData.length;
            } finally {
                this.$wait.end('loading.initial_invoices');
            }
        },

        async handlePrintDownload({ id, download }) {
            const apiToken = Cookies.get('api_token');
            window.open(
                `${process.env.VUE_APP_CORE_API_URL}/clients/clients/${this.clientId}/invoices/${id}/print?api_token=${apiToken}&download=${download}`,
                download ? '_self' : ''
            );
        },
    },
};
</script>
